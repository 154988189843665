import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

const UltimasNoticias = () => {

  const data = useStaticQuery(graphql`
    {
      allWpPost(
        limit: 3
        sort: { fields: [date], order: DESC } # limit: $postsPerPage # skip: $offset
      ) {
        nodes {
          uri
          date(formatString: "DD.MM.YYYY")
          title
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 250
                    aspectRatio: 1
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allWpPost.nodes
  return (
    <aside className="py-20">
      <div className='grid max-w-4xl grid-cols-2 gap-8 px-4 mx-auto lg:grid-cols-3'>
        {posts.map((article) => {
          return (
            <article className='text-xs post lg:text-base' key={article.id}>
              <Link to={article.uri} className='flex flex-col h-full'>
                {article.featuredImage.node ? (


                  <GatsbyImage
                    image={getImage(
                      article.featuredImage.node.localFile.childImageSharp
                    )}
                    alt={article.title}
                    className='mb-2'
                  />
                ) : (
                  <div className='mb-1 bg-green w-60 h-60'></div>
                )}

                <h2 className='mb-4'>{article.title}</h2>
                <span className='mt-auto font-bold border-b-4 place-self-end border-green'>
                  {article.date}
                </span>
              </Link>
            </article>
          )
        })}
      </div>
    </aside>
  )
}

export default UltimasNoticias