import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import parse from 'html-react-parser'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Arrow from '../images/arrow.inline.svg'
import IcoShare from '../images/share.inline.svg'
import IcoTw from '../images/ico-twitter.inline.svg'
import IcoFb from '../images/ico-facebook.inline.svg'
import IcoWa from '../images/ico-whatsapp.inline.svg'


// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css'
import '@wordpress/block-library/build-style/theme.css'

import UltimasNoticias from '../components/ultimas-noticias'
import Layout from '../components/layout'
import Seo from '../components/seo'

const BlogPostTemplate = ({ data: { previous, next, post } }) => {


  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'page-header-white' }}></Helmet>

      <Seo title={post.title} description={post.excerpt} />

      <div className='pt-20 bg-white lg:pt-32'>
        <article
          className='container mx-auto blog-post'
          itemScope
          itemType='http://schema.org/Article'
        >
          <header className='flex'>
            {post.featuredImage.node && (
              <GatsbyImage
                image={getImage(post.featuredImage.node.localFile)}
                alt={post.title}
                className=''
              />
            )}
          </header>

          <div className='grid px-4 lg:gap-8 lg:divide-x lg:pl-0 lg:grid-cols-5'>
            <aside className='pt-8 lg:pt-20'>
              <div className='mb-8 post__meta'>
                <time>{post.date}</time>
              </div>
              <Link
                to='/noticias/'
                className='items-center hidden text-lg uppercase text-orange lg:flex'
              >
                <Arrow className='w-4 h-auto mr-4 transform rotate-180 fill-current stroke-current' />
                Noticias
              </Link>
            </aside>
            {!!post.content && (
              <section
                className='col-span-3 lg:py-20 lg:pl-20 post__content'
                itemProp='articleBody'
              >
                <div className='border-b border-black border-opacity-20 pb-28 post__content'>
                  <h1
                    className='mb-10 text-2xl font-extrabold lg:text-5xl text-green'
                    itemProp='headline'
                  >
                    {parse(post.title)}
                  </h1>

                  {parse(post.content)}
                </div>

                <div className='flex flex-col items-center justify-between pt-6 text-xs lg:flex-row mb-28 sharer'>
                  <div className='flex items-center pb-4 lg:pb-0'>
                    <IcoShare className='w-4 mr-3' />
                    <p className='mb-0' style={{ marginBottom: 0 }}>
                      ¿Te parece interesante?{' '}
                      <strong>Compártelo en tus redes</strong>
                    </p>
                  </div>

                  <ul className='flex items-center space-x-4 lg:ml-auto share-buttons'>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`https://twitter.com/intent/tweet?url=https://saviapackaging.com${post.uri}&text=${post.title}&via=somoscmpc`}
                      className='flex items-center px-2 py-1 rounded-full lg:px-4 share-tw bg-green'
                    >
                      <IcoTw className='w-6 text-black fill-current' />
                      Twitter
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`https://www.facebook.com/sharer.php?u=https://savia.netlify.com${post.uri}`}
                      className='flex items-center px-2 py-1 rounded-full lg:px-4 share-tw bg-green'
                    >
                      <IcoFb className='w-6 text-black fill-current' />
                      Facebook
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`whatsapp://send?text=https://saviapackaging.com${post.uri}`}
                      className='flex items-center px-2 py-1 rounded-full lg:px-4 share-tw bg-green'
                    >
                      <IcoWa className='w-6 text-black fill-current' />
                      WhatsApp
                    </a>
                  </ul>
                </div>
              </section>
            )}
          </div>
        </article>

        {/* <nav className='blog-post-nav'>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.uri} rel='prev'>
                  ← {parse(previous.title)}
                </Link>
              )}
            </li>

            <li>
              {next && (
                <Link to={next.uri} rel='next'>
                  {parse(next.title)} →
                </Link>
              )}
            </li>
          </ul>
        </nav> */}
      </div>
      <hr className='border-black' />
      <UltimasNoticias></UltimasNoticias>
      {/* <Marquee color='black' bgColor='green' /> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      uri
      excerpt
      content
      title
      date(formatString: "DD.MM.YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 2
                width: 1400
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
